label {
  display: inline-block;
  margin-inline: 1em;
  color: white;
  @apply text-zinc-900 dark:text-zinc-100;
  input[type="text"],input[type="number"],input[type="textarea"] {
    display: block;
    border: white solid 1px;
    margin-bottom: 0.5em;
    border-radius: 0.6em;
    @apply border-gray-300 dark:bg-zinc-900;
  }
  width: 13em;
  input[type="checkbox"] {
    border-radius: 0.25em;
    margin-right: 0.5em;
    margin-block: 1em;
  }
}
