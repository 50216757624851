@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  font-family: "Roboto", sans-serif;
}

@layer base {
  :root {
    background-color: white;
  }

  :root[class~="dark"] {
    background-color: #09090b;
  }
}

.required::after {
  content: "*";
  color: #f00;
  margin-left: 3px;
}

.rsw-ce {
  min-height: 200px;
}

.rsw-toolbar {
  background-color: transparent !important;
}

.rsw-btn {
  color: #f4f4f5 !important;
}

.rsw-dd {
  background-color: transparent !important;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

.skeleton-element {
  animation: pulse 1.5s infinite;
}

.skeleton-search-field {
  animation: pulse 1.5s infinite;
}

/* VIEW SWITCHER */

.grid-list {
  padding: 6px 12px 6px 8px;
  margin: 0;
  display: flex;
  outline: none;
  position: relative;
  border: none;
  border-radius: 9px;
  background: var(--b, var(--background));
  cursor: pointer;
  -webkit-appearence: none;
  -webkit-tap-highlight-color: transparent;
  transform: scale(var(--scale, 1)) translateZ(0);
  transition: transform 0.15s, background 0.4s;
  &:active {
    --scale: 0.95;
  }
  &:hover {
    --b: var(--background-hover);
  }
  .icon {
    width: 24px;
    height: 24px;
    position: relative;
    i {
      position: absolute;
      left: var(--left, 4px);
      top: var(--top, 4px);
      display: block;
      border-radius: 2px;
      width: var(--width, 7px);
      height: var(--height, 7px);
      background: var(--icon-color);
      animation: var(--name, var(--dots-name, none))
        var(--duration, var(--dots-duration, 0.5s))
        var(--easing, var(--dots-easing, linear)) forwards
        var(--delay, var(--dots-delay, 0s));
    }
    .dots {
      i {
        &:nth-child(1) {
          --x-middle: -8px;
          --y-middle: 10px;
          --x-end: -2px;
          --y-end: 12px;
          --x-back: 10px;
          --y-back: 7px;
          --x-back-end: 9px;
          --y-back-end: 0;
        }
        &:nth-child(2) {
          --left: 13px;
          --x-middle: -12px;
          --y-middle: 5px;
          --x-end: -11px;
          --y-end: 7px;
          --x-back: -3px;
          --y-back: 1px;
          --x-back-end: -9px;
          --y-back-end: 0;
        }
        &:nth-child(3) {
          --top: 13px;
          --x-middle: 4px;
          --y-middle: -5px;
          --x-end: -2px;
          --y-end: -7px;
          --x-back: -5px;
          --y-back: 0px;
          --x-back-end: 9px;
          --y-back-end: 0;
        }
        &:nth-child(4) {
          --left: 13px;
          --top: 13px;
          --x-middle: 0;
          --y-middle: -10px;
          --x-end: -11px;
          --y-end: -12px;
          --x-back: -14px;
          --y-back: -8px;
          --x-back-end: -9px;
          --y-back-end: 0;
        }
      }
    }
    .lines {
      --name: var(--lines-name, none);
      --duration: var(--lines-duration, 0.15s);
      --easing: var(--lines-easing, linear);
      --delay: var(--lines-delay, 0s);
      i {
        --left: 9px;
        --top: 3px;
        --height: 2px;
        --width: 11px;
        transform: translateY(20%) translateZ(0) scaleX(0);
        &:nth-child(2) {
          --top: 8px;
        }
        &:nth-child(3) {
          --top: 13px;
        }
        &:nth-child(4) {
          --top: 18px;
        }
      }
    }
  }
  .text {
    margin-left: 4px;
    position: relative;
    line-height: 24px;
    font-weight: 600;
    font-size: 14px;
    min-width: 28px;
    color: var(--color);
    span {
      --y-default: 0;
      --o-default: 1;
      --y-active: -12px;
      --o-active: 0;
      display: block;
      opacity: var(--o-default);
      transform: translateY(var(--y-default)) translateZ(0);
      animation: var(--span-name, none) 0.4s ease forwards;
      &:last-child {
        --y-default: 12px;
        --o-default: 0;
        --y-active: 0;
        --o-active: 1;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
  &.animation {
    --span-name: text;
    --dots-name: back;
    --lines-name: scale-down;
    .lines {
      i {
        transform-origin: 0 50%;
        &:nth-child(3),
        &:nth-child(4) {
          transform-origin: 100% 50%;
        }
      }
    }
    &.active {
      --span-name: text-active;
      --dots-name: move;
      --lines-name: scale;
      --lines-duration: 0.15s;
      --lines-delay: 0.3s;
      .lines {
        i {
          transform-origin: 100% 50%;
          &:nth-child(3),
          &:nth-child(4) {
            transform-origin: 0 50%;
          }
        }
      }
    }
  }
}

@keyframes text {
  0% {
    opacity: var(--o-active);
    transform: translateY(var(--y-active)) translateZ(0);
  }
  100% {
    opacity: var(--o-default);
    transform: translateY(var(--y-default)) translateZ(0);
  }
}

@keyframes text-active {
  0% {
    opacity: var(--o-default);
    transform: translateY(var(--y-default)) translateZ(0);
  }
  100% {
    opacity: var(--o-active);
    transform: translateY(var(--y-active)) translateZ(0);
  }
}

@keyframes move {
  50% {
    transform: translate(var(--x-middle, 0), var(--y-middle, 0)) scale(0.4);
  }
  100% {
    transform: translate(var(--x-end, 0), var(--y-end, 0)) scale(0.4);
  }
}

@keyframes back {
  0%,
  15% {
    transform: translate(var(--x-end, 0), var(--y-end, 0)) scale(0.4);
  }
  50% {
    transform: translate(var(--x-back, 0), var(--y-back, 0)) scale(0.5);
  }
  100% {
    transform: translate(var(--x-back-end, 0), var(--y-back-end, 0)) scale(1);
  }
}

@keyframes scale {
  100% {
    transform: translateY(20%) translateZ(0) scaleX(1);
  }
}

@keyframes scale-down {
  0% {
    transform: translateY(20%) translateZ(0) scaleX(1);
  }
  100% {
    transform: translateY(20%) translateZ(0) scaleX(0);
  }
}

/* STEPPER */

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active,
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #4d7c0f !important;
  background-color: white !important;
  border-radius: 50% !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  background: #d4d4d8;
  border-radius: 50%;
}

/* MULTI-SELECT DROPDOWN */

.dropdown-heading {
  height: 36px !important;
  cursor: pointer !important;
}

.dropdown-container {
  height: 36px !important;
  background-color: white !important;
  color: #18181b !important;
  border: 1px solid #d4d4d8 !important;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
}

.dark .dropdown-container {
  background-color: #27272a !important;
  border: none !important;
  color: white !important;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
}

.dropdown-container input::placeholder {
  color: #52525b !important;
}

.dark .dropdown-container input::placeholder {
  color: #d4d4d8 !important;
}

.panel-content {
  background-color: white !important;
}

.dark .panel-content {
  background-color: #27272a !important;
}

.select-item:hover {
  background-color: #f4f4f5 !important;
}

.dark .select-item:hover {
  background-color: #3f3f46 !important;
}

.select-item.selected {
  background-color: white !important;
}

.select-item.selected:hover {
  background-color: #f4f4f5 !important;
}

.dark .select-item.selected {
  background-color: #27272a !important;
}

.dark .select-item.selected:hover {
  background-color: #3f3f46 !important;
}

.search input {
  background-color: white !important;
  box-shadow: none !important;
}

.dark .search input {
  background-color: #3f3f46 !important;
  box-shadow: 0 1px 2px 0 #18181b !important;
}

.dropdown-container .gray {
  color: #52525b !important;
}

.dark .dropdown-container .gray {
  color: #f4f4f5 !important;
}

.dark .dropdown-heading:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.rmsc {
  --rmsc-main: transparent !important;
}

/* QUERY BUILDER */

.ruleGroup {
  background-color: white !important;
  border-color: #d4d4d8 !important;
}

.dark .ruleGroup {
  background-color: #18181b !important;
  border-color: #3f3f46 !important;
}

.MuiButton-root {
  background-color: #4d7c0f !important;
}

.ruleGroup-addRule {
  height: 36px;
  font-size: 14px;
  padding: 3px 12px;
  background-color: rgba(77, 124, 15, 0.9);
  border-radius: 0.25rem;
  color: #f4f4f5;
}

.ruleGroup-addRule:hover {
  background-color: #4d7c0f;
}

.ruleGroup-addGroup {
  height: 36px;
  font-size: 14px;
  padding: 3px 12px;
  border: 1px solid #d4d4d8;
  border-radius: 0.25rem;
  color: #18181b;
  background-color: white;
}

.ruleGroup-addGroup:hover {
  background-color: #e4e4e7;
}

.dark .ruleGroup-addGroup {
  height: 36px;
  font-size: 14px;
  padding: 3px 12px;
  border: 1px solid #52525b;
  border-radius: 0.25rem;
  color: #f4f4f5;
  background-color: #3f3f46;
}

.dark .ruleGroup-addGroup:hover {
  background-color: #52525b;
}

.rule-fields {
  max-width: 300px;
}

.ruleGroup-combinators, .rule-fields, .rule-operators, .rule-value, .rule-value-list-item, .rule-select, .rule-date-input, .rule-number-input {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 8px !important;
  border: 1px solid #d4d4d8;
  border-radius: 0.25rem;
  width: auto;
  height: 36px;
  background-color: white !important;
  border-color: #d4d4d8 !important;
  font-size: 14px !important;
  color: #18181b !important;
}

.dark .ruleGroup-combinators, .dark .rule-fields, .dark .rule-operators, .dark .rule-value, .dark .rule-value-list-item, .dark .rule-select, .dark .rule-date-input, .dark .rule-number-input {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border: 1px solid #3f3f46;
  border-radius: 0.25rem;
  width: auto;
  height: 36px;
  background-color: #27272a !important;
  border-color: #3f3f46 !important;
  font-size: 14px !important;
  color: #f4f4f5 !important;
}

.ruleGroup-combinators:focus, .rule-fields:focus, .rule-operators:focus, .rule-value {
  border: 1px solid #3f3f46;
}

.rule-operators, .rule-select, .ruleGroup-combinators {
  padding-right: 6px !important;
}

.rule-remove, .ruleGroup-remove {
  background-color: red;
  color: #f4f4f5;
  padding: 0px 5px;
  border-radius: 0.25rem;
}

.queryBuilder-dragHandle {
  color: #18181b;
}

.dark .queryBuilder-dragHandle {
  color: #f4f4f5;
}

.ruleGroup-body {
  margin-left: 20px;
}

.ruleGroup::before, .ruleGroup::after, .betweenRules::before, .rule::before, .rule::after {
  border-color: #71717a !important;
}

.dark .ruleGroup::before, .ruleGroup::after, .betweenRules::before, .rule::before, .rule::after {
  border-color: #a1a1aa !important;
}

.css-13cymwt-control, .css-t3ipsp-control {
  min-height: 0px !important;
  height: 36px !important;
  min-width: 250px !important;
}

.dark .css-13cymwt-control, .css-t3ipsp-control {
  background-color: #27272a !important;
  border-color: #3f3f46;
}

.dark .css-b62m3t-container {
  height: 36px !important;
}

/* DRAGGABLE CONTAINER */

.dragging {
  opacity: 0.5;
  position: relative;
  z-index: 50;
  transition: transform 300ms ease;
}

.dragging ~ .draggable-container {
  transition: transform 300ms ease;
  transform: translateY(calc(100% + 16px));
}

/* MASONRY */

.my-masonry-grid {
  display: flex;
  margin-left: -16px;
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 16px;
  background-clip: padding-box;
}
.my-masonry-grid_column > div {
  margin-bottom: 16px;
}

.my-masonry-grid_column > div:last-child {
  margin-bottom: 0;
}

/* DATEPICKER */

.react-datepicker,
.react-datepicker__month-container {
  width: 100% !important;
}

.react-datepicker {
  width: 100% !important;
  background-color: white;
  color: black;
  border: 1px solid #e5e7eb;
}

.react-datepicker__header {
  background-color: #f0f0f0;
  border-bottom: 1px solid #e5e7eb;
}

.react-datepicker__current-month {
  color: black;
}

.react-datepicker__day,
.react-datepicker__day-name {
  color: black;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #4d7c0f !important;
  color: white !important;
  outline: 1px solid #4d7c0f !important;
}

.dark .react-datepicker {
  background-color: #18181b;
  color: #d4d4d8;
  border: 1px solid #3f3f46;
}

.dark .react-datepicker__header {
  background-color: #27272a;
  border-bottom: 1px solid #3f3f46;
}

.dark .react-datepicker__current-month {
  color: #f5f5f5;
}

.dark .react-datepicker__day,
.dark .react-datepicker__day-name {
  color: #d4d4d8;
}

.dark .react-datepicker__day--selected,
.dark .react-datepicker__day--keyboard-selected {
  background-color: #4d7c0f !important;
  color: white !important;
  outline: 1px solid #4d7c0f !important;
}

.dark .react-datepicker__day:hover {
  background-color: #3f3f46 !important;
  color: white !important;
}

