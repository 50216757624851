.Container {
  display: flex;
  flex-direction: column;
  grid-auto-rows: max-content;
  overflow: hidden;
  box-sizing: border-box;
  appearance: none;
  outline: none;
  min-width: 350px;
  min-height: 150px;
  border-radius: 5px;
  font-size: 1em;

  ul {
    list-style: none;
    padding: 20px;
  }

  &.scrollable {
    ul {
      overflow-y: auto;
    }
  }

  &.placeholder {
    justify-content: center;
    align-items: center;
    cursor: pointer;
    min-height: 150px;
    width: 100%;
  }

  &.unstyled {
    overflow: visible;
    background-color: transparent !important;
    border: none !important;
  }

  &.horizontal {
    width: 100%;

    ul {
      grid-auto-flow: column;
    }
  }

  &.shadow {
    box-shadow: 0 1px 10px 0 rgba(34, 33, 81, 0.1);
  }

  &:focus-visible {
    border-color: transparent;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0), 0 0px 0px 2px #4c9ffe;
  }
}

.Header {
  display: flex;
  padding-bottom: 16px;
  padding-top: 16px;
  margin-right: 20px;
  margin-left: 20px;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 15px;
  font-weight: 600;

  &:hover {
    .Actions > * {
      opacity: 1 !important;
    }
  }
}

.Actions {
  display: flex;

  > *:first-child:not(:last-child) {
    opacity: 0;
  }

  > *:nth-child(2):not(:last-child) {
    opacity: 0;
  }

  &:focus-visible {
    opacity: 1;
  }
}
