.disabled {
  cursor: not-allowed;
  pointer-events: none !important;
  opacity: 50%;
}

.tabs {
  ul:nth-of-type(1) {
    border-bottom: 2px solid lightgray;
    padding-bottom: 0;
  }

  ul:nth-of-type(1) li {
    display: inline-block;
    padding: 0;
  }
  ul:nth-of-type(1) li label:hover {
    cursor: pointer;
  }

  ul:nth-of-type(1) li label {
    @apply block w-full border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm;
  }

  ul:nth-of-type(2) li {
    display:none;
    max-height: 80vh;
    min-height: 50vh;
    overflow-y: auto;
    padding-right: 20px;
  }

  input[type="radio"] {
    display:none;
  }

  input[type="radio"]:nth-of-type(1):checked ~ ul:nth-of-type(1) li:nth-of-type(1) label,
  input[type="radio"]:nth-of-type(2):checked ~ ul:nth-of-type(1) li:nth-of-type(2) label
  {
    border-bottom: 5px solid lightblue;
    font-weight: bold;
  }

  input[type="radio"]:nth-of-type(1):checked ~ ul:nth-of-type(2) li:nth-of-type(1),
  input[type="radio"]:nth-of-type(2):checked ~ ul:nth-of-type(2) li:nth-of-type(2)
  {
    padding-top: 20px;
    display:block;
  }
}
